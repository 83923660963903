<template>
  <span class="timestamp">
    {{timestamp}}
  </span>
</template>
<script>
export default {
  props: ['date'],
  computed: {
    timestamp() {
      const dateObj = new Date(parseInt(this.date))
      return dateObj.toLocaleString()
    }
  }
}
</script>
<style>
.timestamp {
}
</style>
