<template>
  <div @click="openCheckout">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ['stripeKey', 'mode'],
  methods: {
    openCheckout() {
      const context = this

      if (this.mode == 'update') {
        this.$http.get('/stripe/subscription/update').then(resp => {
          context.redirectToStripe(resp.data.session_id)
        })
      } else {
        this.$http.get('/stripe/subscription/start').then(resp => {
          context.redirectToStripe(resp.data.session_id)
        })
      }
    },
    redirectToStripe(stripeSessionId) {
      let stripe = Stripe(this.stripeKey)
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: stripeSessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        alert("Something has gone wrong. Please try again.")
      });
    }
  }
}
</script>
