<template>
  <div class="markdown-text" v-html="compiledMarkdown"></div>
</template>
<script>
import marked from 'marked'
import sanitizeHTML from 'sanitize-html'

export default {
  props: ['src'],
  computed: {
    compiledMarkdown() {
      const markdownStr = marked(this.src, {
        breaks: true
      }).replace(/<a href/g, "<a target=\"_blank\" href")
      return sanitizeHTML(markdownStr)
    }
  }
}
</script>
<style>
.markdown-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.markdown-text ul li ul,
.markdown-text ul li ol,
.markdown-text ol li ul,
.markdown-text ol li ol {
  margin-top: 0px;
}
.markdown-text ul li,
.markdown-text ol li,
.markdown-text ul:first-child,
.markdown-text ol:first-child {
  margin-top: 0px;
}
.markdown-text ul:not(first-child),
.markdown-text ol:not(first-child) {
  margin-top: 0.5em;
}
.markdown-text ul:not(last-child),
.markdown-text ol:not(last-child),
.markdown-text p {
  margin-bottom: 0.5em;
}
.markdown-text ul:last-child,
.markdown-text ol:last-child,
.markdown-text p:last-child {
  margin-bottom: 0px;
}
.markdown-text h1,
.markdown-text h2,
.markdown-text h3,
.markdown-text h4,
.markdown-text h5,
.markdown-text h6 {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
